@font-face {
	font-family: "Acumin";
	src: local("Acumin"), url(./fonts/Acumin-RPro.otf) format("opentype");
}

@font-face {
	font-family: "Acumin";
	font-style: italic;
	font-weight: bold;
	src: local("Acumin"), url(./fonts/Acumin-BdItPro.otf) format("opentype");
}
@font-face {
	font-family: "Acumin";
	font-weight: bold;
	src: local("Acumin"), url(./fonts/Acumin-BdPro.otf) format("opentype");
}
@font-face {
	font-family: "Acumin";
	font-style: italic;
	src: local("Acumin"), url(./fonts/Acumin-ItPro.otf) format("opentype");
}

* {
	font-family: "Acumin", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #333;
}

body {
	margin: 0px;
	background-color: #f5f5f5;
	width: 100vw;
}

h2 {
	margin-top: 0px;
}

/* This fixes last pass blocking clicks */

body > div[active] {
	display: block !important;
}

a {
	text-decoration: none;
	color: #000;
}

.red {
	color: red;
}

.interactable {
	cursor: pointer;
}

.MuiInputBase-input {
	background-color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.87) !important;
	-webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
}

.MuiFormLabel-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.6) !important;
	-webkit-text-fill-color: rgba(0, 0, 0, 0.6) !important;
}

.MuiFormControlLabel-label.Mui-disabled {
	color: rgba(0, 0, 0, 0.87) !important;
	-webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
}

.MuiAccordion-root.Mui-expanded {
	margin-top: 0px !important;
}

.MuiInputBase-multiline {
	background-color: white !important;
}

.MuiFormControl-root {
	background-color: white !important;
}
